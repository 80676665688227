var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main"},[_c('OverlayLoader',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}]}),_c('v-container',{staticClass:"text-center"},[(
        Object.keys(_vm.fullExplanations).length !== 0 &&
        _vm.fullExplanations.constructor === Object
      )?_c('v-row',[_c('v-col',[(_vm.predictionTask == 'regression')?_c('v-chart',{staticClass:"chart",attrs:{"option":_vm.chartOptionsRegression,"autoresize":""}}):_vm._e(),(
            _vm.predictionTask == 'classification' &&
            _vm.classificationLabels.length === 2
          )?_c('v-chart',{staticClass:"chart",attrs:{"option":_vm.chartOptionsBinaryClassification,"autoresize":""}}):_vm._e(),(
            _vm.predictionTask == 'classification' &&
            _vm.classificationLabels.length > 2
          )?_c('v-chart',{staticClass:"chart",attrs:{"option":_vm.chartOptionsMultiClassification,"autoresize":""}}):_vm._e()],1)],1):_vm._e(),(_vm.errorMsg)?_c('p',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.errorMsg)+" ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }