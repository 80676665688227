






































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import OverlayLoader from '@/components/OverlayLoader.vue';
import PredictionResults from './PredictionResults.vue';
import PredictionExplanations from './PredictionExplanations.vue';
import TextExplanation from './TextExplanation.vue';
import { api } from '@/api';
import { readToken } from '@/store/main/getters';
import { IDataMetadata } from '@/interfaces';
import FeedbackPopover from '@/components/FeedbackPopover.vue';

@Component({
  components: { OverlayLoader, PredictionResults, FeedbackPopover, PredictionExplanations, TextExplanation }
})
export default class Inspector extends Vue {
	@Prop({ required: true }) modelId!: number
	@Prop({ required: true }) datasetId!: number
  @Prop({ required: true }) originalData!: object // used for the variation feedback
  @Prop({ required: true }) inputData!: object
  @Prop({ default: false }) isMiniMode!: boolean;
  @Prop() targetFeature!: string

  loadingData = false;
  inputMetaData: IDataMetadata[] = [];
  classificationLabels: string[] = []
  predictionTask = ""
  featuresToView: string[] = []
  errorLoadingMetadata = "" 
  dataErrorMsg = ""
  classificationResult = null

  async mounted() {
    await this.loadMetaData();
  }
  
  @Watch('originalData')
  public resetInput() {
    this.$emit('reset');
    (this.$refs.dataFormObserver as HTMLFormElement).reset();
  }

  @Watch('datasetId')
  @Watch('modelId')
  async loadMetaData() {
    this.loadingData = true;
    try {
      const resp = await api.getFeaturesMetadata(readToken(this.$store), this.modelId, this.datasetId)
      this.inputMetaData = resp.data
      this.featuresToView = this.inputMetaData.map(e => e.feat_name)
      
      const respMetadata = await api.getModelMetadata(readToken(this.$store), this.modelId)
      this.classificationLabels = respMetadata.data.classification_labels
      this.predictionTask = respMetadata.data.prediction_task
      this.errorLoadingMetadata = ""
    } catch (e) {
      this.errorLoadingMetadata = e.response.data.detail
    } finally {
      this.loadingData = false;
    }
  }

  get isInputNotOriginal() { // used in case of opening a feedback where original data and input data passed are different 
    return JSON.stringify(this.inputData) !== JSON.stringify(this.originalData)
  }

  get textFeatureNames() {
    return this.inputMetaData.filter(e => e.feat_type == 'text').map(e => e.feat_name)
  }

  public setResult(r) {
    if (this.predictionTask == "classification") this.classificationResult = r
  }

}
