



















































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import OverlayLoader from "@/components/OverlayLoader.vue";
import { api } from "@/api";
import { readToken } from "@/store/main/getters";

@Component({
  components: { OverlayLoader },
})
export default class TextExplanation extends Vue {
  @Prop({ required: true }) modelId!: number;
  @Prop({ required: true }) datasetId!: number;
  @Prop({ required: true }) textFeatureNames!: string[];
  @Prop({ required: true }) classificationLabels!: string[];
  @Prop({ default: {} }) inputData!: object;
  @Prop() classificationResult!: string;

  loading: boolean = false;
  originalInputData = {}; // used for the watcher to trigger the explanation call
  selectedFeature = this.textFeatureNames[0];
  selectedLabel = this.classificationResult || this.classificationLabels[0];
  errorMsg: string = "";
  result: string = "";
  submitted = false;

  @Watch("classificationResult")
  setSelectedLabel() {
    if (
      this.classificationResult &&
      this.classificationLabels.indexOf(this.classificationResult) > 0
    ) {
      this.selectedLabel = this.classificationResult;
    } else this.selectedLabel = this.classificationLabels[0];
  }

  public async getExplanation() {
    if (this.selectedFeature && this.inputData[this.selectedFeature].length) {
      try {
        this.loading = true;
        this.errorMsg = "";
        const response = await api.explainText(
          readToken(this.$store),
          this.modelId,
          this.inputData,
          this.selectedFeature
        );
        this.result = response.data;
        this.submitted = true;
      } catch (error) {
        this.result = "";
        this.errorMsg = error.response.data.detail;
      } finally {
        this.loading = false;
      }
    } else {
      // reset
      this.errorMsg = "";
      this.result = "";
    }
  }
  @Watch("selectedFeature")
  @Watch("inputData", { deep: true })
  reset() {
    this.submitted = false;
    this.result = "";
    this.errorMsg = "";
  }
}
