











































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import OverlayLoader from "@/components/OverlayLoader.vue";
import { api } from "@/api";
import { readToken } from "@/store/main/getters";
import ECharts from "vue-echarts";
import { use } from "echarts/core";
import { BarChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import { GridComponent } from "echarts/components";

use([CanvasRenderer, BarChart, GridComponent]);
Vue.component("v-chart", ECharts);

@Component({
  components: { OverlayLoader },
})
export default class PredictionResults extends Vue {
  @Prop({ required: true }) modelId!: number;
  @Prop({ required: true }) predictionTask!: string;
  @Prop() targetFeature!: string;
  @Prop() classificationLabels!: string[];
  @Prop({ default: {} }) inputData!: object;
  @Prop({ default: false }) modified!: boolean;

  prediction: string | number | undefined = "";
  resultProbabilities: object = {};
  loading: boolean = false;
  errorMsg: string = "";

  mounted() {
    this.submitPrediction()
  }
  
  @Watch("inputData", { deep: true })
  public async submitPrediction() {
    if (Object.keys(this.inputData).length) {
      try {
        this.loading = true;
        const resp = await api.predict(
          readToken(this.$store),
          this.modelId,
          this.inputData
        );
        this.prediction = resp.data.prediction;
        this.$emit("result", this.prediction);
        this.resultProbabilities = resp.data.probabilities
        // Sort the object by value - solution based on:
        // https://stackoverflow.com/questions/55319092/sort-a-javascript-object-by-key-or-value-es6
        this.resultProbabilities = Object.entries(this.resultProbabilities )
          .sort(([, v1], [, v2]) => +v1 - +v2)
          .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
        this.errorMsg = "";
      } catch (error) {
        this.errorMsg = error.response.data.detail;
        this.prediction = undefined;
      } finally {
        this.loading = false;
      }
    } else {
      // reset
      this.errorMsg = "";
      this.prediction = undefined;
      this.resultProbabilities = {};
    }
  }

  get actual() {
    if (this.targetFeature && !this.errorMsg) return this.inputData[this.targetFeature]
    else return undefined
  }

  get actualForDisplay() {
    if (this.actual) {
      if (isNaN(parseInt(this.actual.toString()))) return this.actual;
      else return this.classificationLabels[parseInt(this.actual.toString())];
    } else return "";
  }

  get labelsAndValues() {
    if (this.classificationLabels)
      return this.classificationLabels
        .map((e, idx) => `${idx}: ${e}`)
        .join(", ");
    else return "";
  }

  get chartOptions() {
    return {
      xAxis: {
        type: "value",
        min: 0,
        max: 1,
      },
      yAxis: {
        type: "category",
        data: Object.keys(this.resultProbabilities!),
      },
      series: [
        {
          type: "bar",
          label: {
            show: true,
            position: "right",
            formatter: (params) =>
              params.value % 1 == 0
                ? params.value
                : params.value.toFixed(2).toLocaleString(),
          },
          data: Object.values(this.resultProbabilities!),
        },
      ],
      color: ["#0091EA"],
      grid: {
        width: "80%",
        height: "80%",
        top: "10%",
        left: "10%",
        right: "10%",
        containLabel: true,
      },
    };
  }

  private formatTwoDigits(n: any) {
    try {
      return parseFloat(n).toLocaleString(undefined, {
        maximumFractionDigits: 2,
      });
    } catch (e) {
      return n;
    }
  }
}
