













































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component
export default class FeedbackPopover extends Vue {
  @Prop() inputLabel!: any
  @Prop() inputValue!: any
  @Prop() originalValue!: any
  @Prop() inputType!: any

  opened = false
  selected: string | null = null
  message: string = ""
  submitted = false
  submittedOnce = false

  @Watch('selected')
  @Watch('message')
  public resetSubmitted() {
    this.submitted = false;
  }

  public resetAndClose() {
    this.selected = null
    this.message = ""
    this.opened = false
  }

  @Watch('inputValue')
  public resetAll() {
    this.submittedOnce = false
    this.resetAndClose()
  }

  public submitFeedback() {
    const feedback = {
      feedback_choice: this.selected,
      feedback_message: this.message,
      feature_name: this.inputLabel,
      feature_value: (this.inputValue !== this.originalValue) ? this.originalValue + " -> " + this.inputValue : this.inputValue,
    }
    this.$emit('submit', feedback)
    this.submitted = true;
    this.submittedOnce = true;
  }
}
